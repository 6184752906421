<template>
    <div>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="handleClick(field_tipo,field_id,field_nome_relazione)"
            >
            <feather-icon icon="CalendarIcon" /> Imposta attività
        </b-button>

        <b-modal
        ref="new-modal"
        size="lg"
        centered
        hide-footer
        title="Nuova Attività"
        modal-class="modal-primary"
        @hide="resetNewModal"
        >
        <b-card-text>
            <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                <b-col md="6">
                    <b-form-group label="Data *" label-for="date">
                    <validation-provider
                    #default="{ errors }"
                    name="Data"
                    rules="required"
                    >
                        <b-form-datepicker
                        id="date"
                        v-model="campiform.date"
                        dateFormat="YYYY-MM-DD"
                        start-weekday="1"
                        :min="date_min"
                        placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Ora *" label-for="time_tagliata">
                    <validation-provider
                    #default="{ errors }"
                    name="Ora"
                    rules="required"
                    >
                        <flat-pickr
                          id="time_tagliata"
                          v-model="campiform.time_tagliata"
                          :value="campiform.time_tagliata"
                          class="form-control"
                          :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', time_24hr: true, minuteIncrement: 1 }"
                          placeholder="hh:mm"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Priorità *" label-for="priority_id">
                    <validation-provider
                    #default="{ errors }"
                    name="Priorità"
                    rules="required"
                    >
                        <b-form-select
                        v-model="campiform.priority_id"
                        :options="options_priorita"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Tipo *" label-for="type_id">
                    <validation-provider
                    #default="{ errors }"
                    name="Tipo"
                    rules="required"
                    >
                        <b-form-select
                        v-model="campiform.type_id"
                        :options="options_type"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Assegnato a *" label-for="assigned_to">
                    <validation-provider
                    #default="{ errors }"
                    name="Assegnato a"
                    rules="required"
                    >
                        <b-form-select
                        v-model="campiform.assigned_to"
                        :options="options_utenti_agentscout"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <div v-if="campiform.related_to == 'Lead'">
                    <b-form-group label="Relazionato a Lead *" label-for="related_to">
                        <b-form-input
                        :value="campiform.lead_azienda"
                        disabled
                        />
                    </b-form-group>
                    </div>
                    <div v-else-if="campiform.related_to === 'Customer'">
                    <b-form-group label="Relazionato a Lead *" label-for="related_to">
                        <b-form-input
                        :value="campiform.customer_azienda"
                        disabled
                        />
                    </b-form-group>
                    </div>
                    <div v-else>
                    <b-form-group label="Relazionato a Agente *" label-for="related_to">
                        <b-form-input
                        :value="campiform.agent_nome"
                        disabled
                        />
                    </b-form-group>
                    </div>
                </b-col>
                <b-col md="12">
                    <b-form-group label="Descrizione *" label-for="description">
                    <validation-provider
                    #default="{ errors }"
                    name="Descrizione"
                    rules="required"
                    >
                        <b-form-input
                        id="Descrizione"
                        v-model="campiform.description"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>

                
                </b-row>

                <b-button
                variant="primary"
                type="submit"
                :disabled="action_press"
                @click.prevent="validationForm()"
                >
                <div v-if="action_press">Aggiornamento in corso <b-spinner small class="ml-1" /></div>
                <div v-else>Salva</div> 
                </b-button>
            </b-form>
            </validation-observer>
        </b-card-text>
        </b-modal>
    </div>
</template>

<script>
import { BCard, BCardText, BRow, BCol, BBadge, BPagination, BSpinner, BForm, BFormGroup, BInputGroup, BFormInput, BFormDatepicker, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BButton } from 'bootstrap-vue'
import it from 'vee-validate/dist/locale/it.json'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import {
  required, digits, length, min
} from '@validations'

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    it,
    BCard, 
    BCardText,
    BRow, 
    BCol, 
    BBadge,
    BPagination,
    BSpinner,
    BInputGroup,
    BFormGroup,
    BForm,
    BFormInput,
    BFormDatepicker, 
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,
    BButton,

    flatPickr,
  },
  props: {
    field_tipo: '',
    field_id: '',
    field_nome_relazione: '',
  },
  data() {
    return {
        userData: {},

        campiform: {
            status_id: 2,
            date: '',
            time_tagliata: '',
            time: '',
            priority_id: 1,
            type_id: 1,
            assigned_to: '',
            related_to: '',
            related_to_customer: null,
            related_to_lead: null, 
            related_to_agent: null, 
            description: '',
        },
        options_priorita: [
            { value: 1, text: 'Urgente' }, 
            { value: 2, text: 'Alta' },
            { value: 3, text: 'Media' },
            { value: 4, text: 'Bassa' },
        ],
        options_type: [
            { value: 1, text: 'Assistenza' }, 
            { value: 2, text: 'Vendita' },
            { value: 3, text: 'Recupero crediti' },
        ], 
        options_utenti_agentscout: [],
        required,
        digits,
        length,
        min,
        action_press: false,

        isLoading: true,

        date_min: '',

        serverParams: {
          columnFilters: {
          },
          sort: [
              {
              field: 'name',
              type: 'asc'
              }
          ],
          page: 1, 
          perPage: 20
        },
    }
  },
  created() {
    console.log("componente on create");

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    this.campiform.date = today;
    this.date_min = today;

    this.listaEmployee();
    //auto selezione proprio utente nel select
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.campiform.assigned_to = this.userData.id
  },  
  methods: {
    listaEmployee() {
      this.$http.get('api/crm/user/list?columnFilters={"role.type":"employee"}&sort[]={"field":"name","type":"asc"}&page=1&perPage=100&status=active').then(response => { 
        if(response.data.statusCode == 200){
          //ok
          let i = 0;
          while (i < response.data.reply.meta.total) {
            this.options_utenti_agentscout[i] = { value: response.data.reply.data[i].id, text: response.data.reply.data[i].name+' '+response.data.reply.data[i].surname },
            
            i++;
          }

        } else {
          //lista manuale ... lascio inalterata
        }      
      })
    },
    handleClick(tipo, id, nome_da_visualizzare) {
        console.log("componente => id: "+id+" | tipo: "+tipo+" | nome: "+nome_da_visualizzare)

        this.campiform.related_to = tipo;

        if(tipo == 'Lead'){
          this.campiform.lead_azienda = nome_da_visualizzare;
          this.campiform.related_to_lead = id;
        } else if(tipo == 'Customer'){
          this.campiform.customer_azienda = nome_da_visualizzare;
          this.campiform.related_to_customer = id;
        } else {
          //agente
          this.campiform.agent_nome = nome_da_visualizzare;
          this.campiform.related_to_agent = id;
        }

        this.$refs['new-modal'].show()
    },
    resetNewModal(){
      this.campiform = {
        status_id: 2,
        date: '',
        time_tagliata: '',
        time: '',
        priority_id: 3,
        type_id: 1,
        assigned_to: 1,
        related_to: '',
        related_to_customer: null,
        related_to_lead: null, 
        related_to_agent: null, 
        description: '',
      }
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          //console.log("Speriamo bene --------------------------")
          //console.log(this.campiform)

          //cancella campi superflui per il salvataggio (usati solo per la vista)
          delete(this.campiform.lead_azienda);
          delete(this.campiform.customer_azienda);
          delete(this.campiform.agent_nome);
          
          this.campiform.time = this.campiform.time_tagliata+':00'; //aggiungo i secondi che nel DB ci vogliono!

          delete(this.campiform.time_tagliata);

          console.log("DOPO Speriamo bene --------------------------")
          console.log(this.campiform)
          console.log("related_to -> "+this.campiform.related_to)

          this.$http.post('api/crm/activities/card', 
            this.campiform
          ).then(response => { 
            //console.log("risposta server --------")
            //console.log(response.data)

            //chiudi finestra
            this.$refs['new-modal'].hide();

            this.$swal({
              icon: 'success',
              title: response.data.reply.reply,
              //text: 'operazione effettuata correttamente',
              confirmButtonText: 'chiudi',
              customClass: {
              confirmButton: 'btn btn-success',
              },
            })

          }).catch(e => {
            console.log(e);
          });

        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>