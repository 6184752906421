var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1",attrs:{"variant":"outline-warning","block":""},on:{"click":function($event){return _vm.handleClick(_vm.field_tipo,_vm.field_id,_vm.field_nome_relazione)}}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}}),_vm._v(" Imposta attività ")],1),_c('b-modal',{ref:"new-modal",attrs:{"size":"lg","centered":"","hide-footer":"","title":"Nuova Attività","modal-class":"modal-primary"},on:{"hide":_vm.resetNewModal}},[_c('b-card-text',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Data *","label-for":"date"}},[_c('validation-provider',{attrs:{"name":"Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"date","dateFormat":"YYYY-MM-DD","start-weekday":"1","min":_vm.date_min,"placeholder":""},model:{value:(_vm.campiform.date),callback:function ($$v) {_vm.$set(_vm.campiform, "date", $$v)},expression:"campiform.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Ora *","label-for":"time_tagliata"}},[_c('validation-provider',{attrs:{"name":"Ora","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"time_tagliata","value":_vm.campiform.time_tagliata,"config":{ enableTime: true, noCalendar: true, dateFormat: 'H:i', time_24hr: true, minuteIncrement: 1 },"placeholder":"hh:mm"},model:{value:(_vm.campiform.time_tagliata),callback:function ($$v) {_vm.$set(_vm.campiform, "time_tagliata", $$v)},expression:"campiform.time_tagliata"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Priorità *","label-for":"priority_id"}},[_c('validation-provider',{attrs:{"name":"Priorità","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options_priorita},model:{value:(_vm.campiform.priority_id),callback:function ($$v) {_vm.$set(_vm.campiform, "priority_id", $$v)},expression:"campiform.priority_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tipo *","label-for":"type_id"}},[_c('validation-provider',{attrs:{"name":"Tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options_type},model:{value:(_vm.campiform.type_id),callback:function ($$v) {_vm.$set(_vm.campiform, "type_id", $$v)},expression:"campiform.type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Assegnato a *","label-for":"assigned_to"}},[_c('validation-provider',{attrs:{"name":"Assegnato a","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options_utenti_agentscout},model:{value:(_vm.campiform.assigned_to),callback:function ($$v) {_vm.$set(_vm.campiform, "assigned_to", $$v)},expression:"campiform.assigned_to"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[(_vm.campiform.related_to == 'Lead')?_c('div',[_c('b-form-group',{attrs:{"label":"Relazionato a Lead *","label-for":"related_to"}},[_c('b-form-input',{attrs:{"value":_vm.campiform.lead_azienda,"disabled":""}})],1)],1):(_vm.campiform.related_to === 'Customer')?_c('div',[_c('b-form-group',{attrs:{"label":"Relazionato a Lead *","label-for":"related_to"}},[_c('b-form-input',{attrs:{"value":_vm.campiform.customer_azienda,"disabled":""}})],1)],1):_c('div',[_c('b-form-group',{attrs:{"label":"Relazionato a Agente *","label-for":"related_to"}},[_c('b-form-input',{attrs:{"value":_vm.campiform.agent_nome,"disabled":""}})],1)],1)]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Descrizione *","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Descrizione","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Descrizione","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.description),callback:function ($$v) {_vm.$set(_vm.campiform, "description", $$v)},expression:"campiform.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.action_press},on:{"click":function($event){$event.preventDefault();return _vm.validationForm()}}},[(_vm.action_press)?_c('div',[_vm._v("Aggiornamento in corso "),_c('b-spinner',{staticClass:"ml-1",attrs:{"small":""}})],1):_c('div',[_vm._v("Salva")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }